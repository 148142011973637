/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Default variables for light mode */
:root {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #f9fafb;
  --color-bg-tertiary: #f3f4f6;
  --color-text-primary: #111827;
  --color-text-secondary: #4b5563;
  --color-text-tertiary: #6b7280;
  --color-border: #e5e7eb;
  --color-input-bg: #ffffff;
  --color-input-border: #d1d5db;
  --color-sidebar: #ffffff;
  --color-card: #ffffff;
  --color-card-hover: #f9fafb;
  --color-accent: #2563eb;
  --color-accent-hover: #1d4ed8;
  --color-accent-light: #eff6ff;
  --color-success: #10b981;
  --color-error: #ef4444;
  --color-warning: #f59e0b;
  --color-modal-overlay: rgba(0, 0, 0, 0.5);
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  
  /* Hover effect colors - light mode */
  --color-hover-light: #f3f4f6;     /* Very light gray for light mode hover */
  --color-hover-btn: #e5e7eb;       /* Light gray for button hovers */
  --color-hover-primary: #dbeafe;   /* Very light blue for primary element hovers */
  --color-hover-success: #d1fae5;   /* Very light green for success element hovers */
  --color-hover-error: #fee2e2;     /* Very light red for error/danger element hovers */
}

/* Dark mode variables */
.dark-mode {
  --color-bg-primary: #111827;
  --color-bg-secondary: #1f2937;
  --color-bg-tertiary: #374151;
  --color-text-primary: #f9fafb;
  --color-text-secondary: #e5e7eb;
  --color-text-tertiary: #d1d5db;
  --color-border: #374151;
  --color-input-bg: #1f2937;
  --color-input-border: #4b5563;
  --color-sidebar: #1f2937;
  --color-card: #1f2937;
  --color-card-hover: #374151;
  --color-accent: #3b82f6;
  --color-accent-hover: #60a5fa;
  --color-accent-light: #1e3a8a;
  --color-success: #059669;
  --color-error: #dc2626;
  --color-warning: #d97706;
  --color-modal-overlay: rgba(0, 0, 0, 0.7);
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
  
  /* Hover effect colors - dark mode */
  --color-hover-light: #374151;     /* Dark gray for dark mode hover */
  --color-hover-btn: #4b5563;       /* Medium gray for button hovers */
  --color-hover-primary: #1e40af;   /* Deep blue for primary element hovers */
  --color-hover-success: #065f46;   /* Deep green for success element hovers */
  --color-hover-error: #991b1b;     /* Deep red for error/danger element hovers */
}

/* Light mode is default, this class is added for explicitness */
.light-mode {
  /* Light mode uses default variables */
}

/* Apply CSS variables to elements */
body {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Custom button styles */
@layer components {
  .btn-primary {
    @apply bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition-colors;
    background-color: var(--color-accent);
  }
  
  .btn-primary:hover {
    background-color: var(--color-accent-hover);
  }
  
  .btn-secondary {
    @apply bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded-md transition-colors;
    background-color: var(--color-bg-tertiary);
    color: var(--color-text-secondary);
  }
  
  .btn-secondary:hover {
    background-color: var(--color-hover-btn);
  }
  
  .btn-danger {
    @apply bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md transition-colors;
    background-color: var(--color-error);
  }
  
  .input-primary {
    @apply border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent;
    background-color: var(--color-input-bg);
    border-color: var(--color-input-border);
    color: var(--color-text-primary);
  }
}

/* Card styling */
.card {
  background-color: var(--color-card);
  border-color: var(--color-border);
  box-shadow: var(--shadow-md);
}

/* Form elements */
input, select, textarea {
  background-color: var(--color-input-bg);
  border-color: var(--color-input-border);
  color: var(--color-text-primary);
}

input::placeholder, textarea::placeholder {
  color: var(--color-text-tertiary);
}

/* Table styling */
table {
  border-color: var(--color-border);
}

thead {
  background-color: var(--color-bg-tertiary);
}

th {
  color: var(--color-text-secondary);
}

tr:hover {
  background-color: var(--color-hover-light);
}

/* Modal styling */
.modal-overlay {
  background-color: var(--color-modal-overlay);
}

.modal-content {
  background-color: var(--color-bg-primary);
  border-color: var(--color-border);
  box-shadow: var(--shadow-lg);
}

/* Links */
a {
  color: var(--color-accent);
}

a:hover {
  color: var(--color-accent-hover);
}

/* Transitions for smooth theme switching */
* {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Transition specifically for hover effects */
.transition-hover {
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--color-bg-secondary);
}

::-webkit-scrollbar-thumb {
  background: var(--color-bg-tertiary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-border);
}

/* Animations */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}

.animate-slideDown {
  animation: slideDown 0.5s ease-out forwards;
}

.animate-slideUp {
  animation: slideUp 0.5s ease-out forwards;
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}

.animate-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}